import { useState, useRef } from "react";
import { setClass } from "../../../../state/linkManagement/linkManagementSlice";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
} from "@mui/material";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import PublicIcon from "@mui/icons-material/Public";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
export default function SelectClass() {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const { contentClass } = useSelector(
    (state: RootState) => state.linkManagement
  );
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={
          contentClass === "link" ? (
            <LinkOutlinedIcon fontSize="small" />
          ) : contentClass === "category" ? (
            <CategoryIcon />
          ) : (
            <PublicIcon />
          )
        }
      >
        Class
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => dispatch(setClass("all"))}>
                      <ListItemIcon
                        sx={{
                          minWidth: "35px",
                        }}
                      >
                        <PublicIcon />
                      </ListItemIcon>
                      <ListItemText primary="All" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => dispatch(setClass("link"))}>
                      <ListItemIcon
                        sx={{
                          minWidth: "35px",
                        }}
                      >
                        <LinkOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Link" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => dispatch(setClass("category"))}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "35px",
                        }}
                      >
                        <CategoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Category" />
                    </ListItemButton>
                  </ListItem>
                </List>
                {/* <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() => {
                      dispatch(setClass("all"));
                      setOpen(false);
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      dispatch(setClass("link"));
                      setOpen(false);
                    }}
                  >
                    Links
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      dispatch(setClass("category"));
                      setOpen(false);
                    }}
                  >
                    Categories
                  </MenuItem>
                </MenuList> */}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
